import {
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

import AspectRatioBox from "../Components/AspectRatioBox";

export default function ServiceArea() {
  const serviceAreaArray = [
    {
      title: "| 關於我們",
      body:
        "重視客戶傳遞的理念，量身打造客戶專屬，舒適獨特的居住空間，提供溫暖細膩的服務。",
      image: require("../images/about.png"),
    },
    {
      title: "| 室內設計",
      body:
        "從動線、空間、色彩、照明、功能，將帶領你體驗建築的饗宴，設計的巧思帶來神祕的驚喜。",
      image: require("../images/indoor.png"),
    },
    {
      title: "| 商空設計",
      body:
        "創新理念，多重設計，實用性的訴求，藝術性的追求，華納文本在商與美之間達到完美平衡。",
      image: require("../images/Commercial.png"),
    },
  ];
  return (
    <>
      {serviceAreaArray.map((item, index) => (
        <Box id={index === 0 ? "about" : "service"}>
          {React.createElement(
            index % 3 === 0 ? ServiceGradientBox : ServiceBox,
            { data: item, direction: index % 2 ? "left" : "right" },
            null
          )}
        </Box>
      ))}
    </>
  );
}
const boxAreaProps = {
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: 300,
  position: "relative",
};
function ServiceBox({ direction, data }) {
  const theme = useTheme();
  const deviceSize = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    textBody: {
      textAlign: deviceSize ? "center" : "justify",
      textJustify: "inter-character",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  });
  const classes = useStyles();
  function TextBody() {
    return (
      <>
        <Typography variant="h6" paragraph>
          {data.title}
        </Typography>
        {data.body.split("\n").map((item) => (
          <Typography classes={{ root: classes.textBody }} gutterBottom>
            {item}
          </Typography>
        ))}
      </>
    );
  }
  return (
    <Box {...boxAreaProps}>
      {deviceSize ? (
        <Box
          position="absolute"
          width="100%"
          height="100%"
          padding={5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor={`rgba(0,0,0,0.7)`}
          color={theme.palette.common.white}
        >
          <TextBody />
        </Box>
      ) : (
        <AspectRatioBox
          height="100%"
          w={4}
          h={3}
          style={{
            backgroundColor:
              theme.palette[direction === "left" ? "primary" : "secondary"]
                .main,
            order: direction === "right" ? 1 : 0,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: theme.spacing(5),
            color: theme.palette.common.white,
          }}
        >
          <TextBody />
        </AspectRatioBox>
      )}
      <Box display="flex" height="100%" flex={1}>
        <img src={data.image} className={classes.image} />
      </Box>
    </Box>
  );
}
function ServiceGradientBox({ direction, data, ...props }) {
  const theme = useTheme();
  const deviceSize = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    boxArea: {
      display: "flex",
      justifyContent: deviceSize
        ? "center"
        : direction === "right" && "flex-end",
      position: "absolute",
      height: "100%",
      width: "100%",
      background: deviceSize
        ? "rgb(0,0,0, 0.7)"
        : direction === "left"
        ? `linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)`
        : `linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)`,
      color: "white",
    },
    textBody: {
      textAlign: deviceSize ? "center" : "justify",
      textJustify: "inter-character",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  });
  const classes = useStyles();
  function TextBody() {
    return (
      <>
        <Typography variant="h6" paragraph>
          {data.title}
        </Typography>
        {data.body.split("\n").map((item) => (
          <Typography classes={{ root: classes.textBody }} gutterBottom>
            {item}
          </Typography>
        ))}
      </>
    );
  }
  return (
    <Box {...props} {...boxAreaProps}>
      <img src={data.image} className={classes.image} />
      <Box classes={{ root: classes.boxArea }}>
        {deviceSize ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            padding={5}
          >
            <TextBody />
          </Box>
        ) : (
          <AspectRatioBox
            height="100%"
            w={4}
            h={3}
            style={{
              flexDirection: "column",
              justifyContent: "center",
              padding: theme.spacing(5),
            }}
          >
            <TextBody />
          </AspectRatioBox>
        )}
      </Box>
    </Box>
  );
}
