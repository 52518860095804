import { Box, Grid, useTheme, Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
export default function Carousel() {
  const theme = useTheme();
  const useStyles = makeStyles({
    imgIndexButton: {
      padding: 0,
      minWidth: 0,
      width: "100%",
      height: theme.spacing(1),
    },
  });
  const classes = useStyles();
  const [imgIndex, setImgIndex] = useState(0);
  const imgArray = [
    require("../images/carousel1.jpg"),
    require("../images/carousel2.jpg"),
    require("../images/carousel3.jpg"),
    require("../images/carousel4.jpg"),
    require("../images/carousel5.jpg"),
    require("../images/carousel6.jpg"),
  ];
  return (
    <Box position="relative">
      <AutoPlaySwipeableViews
        index={imgIndex}
        onChangeIndex={setImgIndex}
        enableMouseEvents
        slideStyle={{
          width: "100%",
          height: `calc(100vh - 72px)`,
          display: "flex",
        }}
      >
        {imgArray.map((item) => (
          <img
            src={item}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        ))}
      </AutoPlaySwipeableViews>
      <Box
        width="100%"
        position="absolute"
        padding={`0 ${theme.spacing(1)}px`}
        bottom={theme.spacing(1)}
      >
        <Grid container justify="center" spacing={1}>
          {imgArray.map((item, index) => (
            <Grid item xs={2} sm={2} md={1}>
              <Button
                variant="contained"
                color={index === imgIndex ? "primary" : "default"}
                onClick={() => setImgIndex(index)}
                classes={{ root: classes.imgIndexButton }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
