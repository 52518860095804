import React, { useState } from "react";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

export default function NavBar() {
  const theme = useTheme();
  const tableDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const boxHeight = 72;
  const [openMenu, setOpenMenu] = useState(false);
  const useStyles = makeStyles({
    buttonArea: {
      "& > *": {
        margin: `0 ${theme.spacing(4)}px`,
        cursor: "pointer",
      },
    },
    collapseButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: theme.spacing(0.5),
      height: boxHeight,
    },
  });
  const classes = useStyles();
  const topMenuArray = [
    { title: "關於我們", jumpTo: "about" },
    { title: "服務項目", jumpTo: "service" },
    { title: "作品總覽", jumpTo: "overview" },
  ];
  function _scrollTo(id) {
    const anchor = document.querySelector(`#${id}`).offsetTop - boxHeight;
    return window.scroll({ top: anchor, behavior: "smooth" });
  }
  return (
    <>
      <Box height={boxHeight} />
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-evenly"
        padding={`${theme.spacing(2)}px ${theme.spacing(4)}px`}
        bgcolor={theme.palette.common.white}
        zIndex={theme.zIndex.appBar}
        width="100%"
        position="fixed"
        top={0}
      >
        <Box display="flex" alignItems="center">
          <img
            src={require("../images/deer.png")}
            style={{ width: 40, height: 40, marginRight: theme.spacing(1) }}
          />
          <Typography variant="h6" color="primary">
            華納文本
          </Typography>
        </Box>
        <Slide in={tableDevice} mountOnEnter unmountOnExit>
          <IconButton
            onClick={() => setOpenMenu((e) => !e)}
            style={{
              position: "absolute",
              right: theme.spacing(4),
              transform: `translate(${theme.spacing(4)},${theme.spacing(2)})`,
            }}
          >
            <MenuIcon />
          </IconButton>
        </Slide>
        <Slide in={!tableDevice} mountOnEnter unmountOnExit>
          <Box display="flex" classes={{ root: classes.buttonArea }}>
            <>
              {topMenuArray.map((item) => (
                <Typography
                  variant="h6"
                  color="primary"
                  onClick={() => _scrollTo(item.jumpTo)}
                >
                  {item.title}
                </Typography>
              ))}
            </>
          </Box>
        </Slide>
        {tableDevice && (
          <Collapse
            in={openMenu}
            style={{
              position: "absolute",
              top: boxHeight,
              zIndex: theme.zIndex.tooltip,
              width: "100%",
              backgroundColor: theme.palette.common.white,
            }}
          >
            <Grid container>
              {topMenuArray.map((item) => (
                <Grid container item xs={12} sm={4} justify="center">
                  <Box
                    classes={{ root: classes.collapseButton }}
                    onClick={() => {
                      _scrollTo(item.jumpTo);
                      setOpenMenu(false);
                    }}
                  >
                    <Typography variant="h6" color="primary">
                      {item.title}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Collapse>
        )}
      </Box>
    </>
  );
}
