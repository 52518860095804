import React, { useState, useEffect } from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  useTheme,
  makeStyles,
  Grid,
} from "@material-ui/core";

export default function ImageNavBar({
  onChangeImageType = () => {},
  ...props
}) {
  const theme = useTheme();
  const useStyles = makeStyles({
    tabs: {
      height: 40,
      minHeight: 40,
    },
    tab: {
      height: 40,
      minHeight: 40,
    },
  });
  const classes = useStyles();
  const [imgSelect, setImgSelect] = useState("");
  const imgMenuArray = [
    { title: "作品", value: "" },
    { title: "領航者", value: "itabashiNavigator" },
    { title: "京澄無為", value: "jingchengWuwei" },
    { title: "合雄涵悅", value: "nankanHexiongHanyue" },
    { title: "青田", value: "nankanQingtian" },
    { title: "水岸大宅", value: "nankanWaterfrontMansion" },
    { title: "中悅企業總部", value: "zhongyueCorporateHeadquarters" },
    { title: "楊梅晴朗", value: "bayberrySunny" },
  ];
  useEffect(() => {
    onChangeImageType(imgSelect);
  }, [imgSelect]);
  return (
    <Box
      padding={2}
      bgcolor={theme.palette.common.white}
      position="relative"
      {...props}
    >
      <Grid container spacing={1}>
        <Grid container item justify="center" alignItems="center">
          <img
            src={require("../images/deer.png")}
            style={{ width: 40, height: 40, marginRight: theme.spacing(1) }}
          />
          <Typography variant="h6" color="primary">
            華納文本
          </Typography>
        </Grid>
        <Grid container item>
          <Tabs
            value={imgSelect}
            onChange={(e, n) => setImgSelect(n)}
            variant="scrollable"
            scrollButtons="on"
            textColor="primary"
            indicatorColor="primary"
            classes={{ root: classes.tabs }}
          >
            {imgMenuArray.map((item) => (
              <Tab
                label={item.title}
                value={item.value}
                classes={{ root: classes.tab }}
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>
    </Box>
  );
}
