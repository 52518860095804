import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Home from "./pages/Home";

function App() {
  const theme = createMuiTheme({
    palette: { primary: { main: "#C6A78E" }, secondary: { main: "#C7BAA9" } },
    typography: {
      fontFamily: ["Noto Sans TC", "sans-serif"].join(","),
    },
  });
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
