import React from "react";
import { Box } from "@material-ui/core";

import NavBar from "../Components/NavBar";
import Carousel from "../Components/Carousel";
import ServiceArea from "../Components/ServiceArea";
import ImageArea from "../Components/ImageArea";
import FooterArea from "../Components/FooterArea";

export default function Home() {
  return (
    <Box position="relative">
      <NavBar />
      {/* about */}
      <Carousel />
      {/* service */}
      <ServiceArea />
      {/* overview */}
      <ImageArea />
      {/* 頁尾資訊 */}
      <FooterArea />
    </Box>
  );
}
