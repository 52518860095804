import React, { useEffect, useReducer, useState } from "react";
import {
  Grid,
  Box,
  ButtonBase,
  Slide,
  Modal,
  makeStyles,
  IconButton,
  useTheme,
  Fade,
  Grow,
  Backdrop,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";

import ImageNavBar from "../Components/ImageNavBar";
import AspectRatioBox from "../Components/AspectRatioBox";
import SwipeableViews from "react-swipeable-views";

export default function ImageArea() {
  const [imageType, setImageType] = useState("");
  const [openImage, setOpenImage] = useState(false);
  const [imageProps, imagePropsDisPatch] = useReducer(_imagePropsDisPatch, {
    type: "close",
    index: null,
    data: [],
  });
  function _imagePropsDisPatch(state, action) {
    switch (action.type) {
      case "open":
        return { index: action.index, data: action.data };
      case "close": //關閉視同預設
      default:
        return { index: null, data: [] };
    }
  }
  useEffect(() => {
    setOpenImage(Boolean(imageProps.data.length > 0));
  }, [imageProps]);
  const useStyles = makeStyles({
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      transition: "all 0.25s",
      "&:hover": {
        filter: `brightness(.5)`,
      },
    },
  });
  const classes = useStyles();
  const itabashiNavigator = [
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_0.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_1.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_2.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_3.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_4.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_5.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_6.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_7.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_8.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_9.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_10.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_11.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_12.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_13.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_14.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_15.jpg"),
    require("../images/ItabashiNavigator/c8d605ac11d6844027db62645ce653650_38263505_201027_16.jpg"),
  ];
  const jingchengWuwei = [
    require("../images/JingchengWuwei/c8d605ac11d6844027db62645ce653650_38265374_201027_0.jpg"),
    require("../images/JingchengWuwei/c8d605ac11d6844027db62645ce653650_38265374_201027_1.jpg"),
    require("../images/JingchengWuwei/c8d605ac11d6844027db62645ce653650_38265374_201027_2.jpg"),
    require("../images/JingchengWuwei/c8d605ac11d6844027db62645ce653650_38265374_201027_3.jpg"),
  ];
  const nankanHexiongHanyue = [
    require("../images/NankanHexiongHanyue/c8d605ac11d6844027db62645ce653650_38263955_201027_0.jpg"),
    require("../images/NankanHexiongHanyue/c8d605ac11d6844027db62645ce653650_38263955_201027_1.jpg"),
    require("../images/NankanHexiongHanyue/c8d605ac11d6844027db62645ce653650_38263955_201027_2.jpg"),
  ];
  const nankanQingtian = [
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_0.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_1.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_2.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_3.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_4.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_5.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_6.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_7.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_8.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_9.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_10.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_11.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_12.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_13.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_14.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_15.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_16.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_17.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_18.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_19.jpg"),
    require("../images/NankanQingtian/c8d605ac11d6844027db62645ce653650_38264845_201027_20.jpg"),
  ];
  const nankanWaterfrontMansion = [
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_0.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_1.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_2.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_3.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_4.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_5.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_6.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_7.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_8.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_9.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_10.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_11.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_12.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_13.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_14.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_15.jpg"),
    require("../images/NankanWaterfrontMansion/c8d605ac11d6844027db62645ce653650_38262509_201027_16.jpg"),
  ];
  const zhongyueCorporateHeadquarters = [
    require("../images/ZhongyueCorporateHeadquarters/c8d605ac11d6844027db62645ce653650_38264354_201027_0.jpg"),
    require("../images/ZhongyueCorporateHeadquarters/c8d605ac11d6844027db62645ce653650_38264354_201027_1.jpg"),
    require("../images/ZhongyueCorporateHeadquarters/c8d605ac11d6844027db62645ce653650_38264354_201027_2.jpg"),
    require("../images/ZhongyueCorporateHeadquarters/c8d605ac11d6844027db62645ce653650_38264354_201027_3.jpg"),
  ];
  const bayberrySunny = [
    require("../images/BayberrySunny/c8d605ac11d6844027db62645ce653650_38265513_201027_0.jpg"),
    require("../images/BayberrySunny/c8d605ac11d6844027db62645ce653650_38265513_201027_1.jpg"),
    require("../images/BayberrySunny/c8d605ac11d6844027db62645ce653650_38265513_201027_2.jpg"),
    require("../images/BayberrySunny/c8d605ac11d6844027db62645ce653650_38265513_201027_3.jpg"),
    require("../images/BayberrySunny/c8d605ac11d6844027db62645ce653650_38265513_201027_4.jpg"),
    require("../images/BayberrySunny/c8d605ac11d6844027db62645ce653650_38265513_201027_5.jpg"),
    require("../images/BayberrySunny/c8d605ac11d6844027db62645ce653650_38265513_201027_6.jpg"),
  ];
  const allImage = [
    ...itabashiNavigator,
    ...jingchengWuwei,
    ...nankanHexiongHanyue,
    ...nankanQingtian,
    ...nankanWaterfrontMansion,
    ...zhongyueCorporateHeadquarters,
    ...bayberrySunny,
  ];
  const imageFilter = [
    { type: "", data: allImage },
    { type: "itabashiNavigator", data: itabashiNavigator },
    { type: "jingchengWuwei", data: jingchengWuwei },
    { type: "nankanHexiongHanyue", data: nankanHexiongHanyue },
    { type: "nankanQingtian", data: nankanQingtian },
    { type: "nankanWaterfrontMansion", data: nankanWaterfrontMansion },
    {
      type: "zhongyueCorporateHeadquarters",
      data: zhongyueCorporateHeadquarters,
    },
    { type: "bayberrySunny", data: bayberrySunny },
  ];
  return (
    <>
      <ImageNavBar onChangeImageType={setImageType} id="overview" />
      <Box padding={1}>
        {imageFilter.map((item) => (
          <Slide
            mountOnEnter
            unmountOnExit
            direction="up"
            in={imageType === item.type}
          >
            <Grid container spacing={1}>
              {item.data.map((item2, index2) => (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                  key={item2.type + index2}
                >
                  <AspectRatioBox width="100%">
                    <ButtonBase
                      style={{ flex: 1 }}
                      onClick={() =>
                        imagePropsDisPatch({
                          type: "open",
                          index: index2,
                          data: item.data,
                        })
                      }
                    >
                      <img src={item2} className={classes.image} />
                    </ButtonBase>
                  </AspectRatioBox>
                </Grid>
              ))}
            </Grid>
          </Slide>
        ))}
      </Box>
      <ImageModal
        open={openImage}
        onClose={() => imagePropsDisPatch({ type: "close" })}
        data={imageProps}
      />
    </>
  );
}

function ImageModal({ data, ...props }) {
  const theme = useTheme();
  const [imageIndex, setImageIndex] = useState(0);
  const [showButton, setShowButton] = useState(true);
  useEffect(() => {
    if (data) {
      setImageIndex(data.index || 0);
    }
  }, [data]);
  const useStyles = makeStyles({
    button: {
      color: theme.palette.common.white,
      margin: theme.spacing(1),
    },
    closeButton: {
      color: theme.palette.common.white,
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    modal: {
      backgroundColor: theme.palette.grey[900],
    },
  });
  const classes = useStyles();
  function _nextImage() {
    if (data.data) {
      setImageIndex((e) => (e < data.data.length - 1 ? e + 1 : 0));
    }
  }
  function _previousImage() {
    if (data.data) {
      setImageIndex((e) => (e > 0 ? e - 1 : data.data.length - 1));
    }
  }
  const growTimeOut = {
    mountOnEnter: true,
    unmountOnExit: true,
    timeout: { appear: 250, enter: 250, exit: 0 },
  };
  return (
    <Modal
      {...props}
      closeAfterTransition
      BackdropProps={{
        classes: { root: classes.modal },
        timeout: 250,
      }}
      BackdropComponent={Backdrop}
    >
      <Fade in={props.open}>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          height="100%"
          position="relative"
        >
          <Grow in={showButton} {...growTimeOut}>
            <IconButton
              classes={{ root: classes.button }}
              onClick={_previousImage}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Grow>
          <SwipeableViews
            index={imageIndex}
            onChangeIndex={setImageIndex}
            onClick={() => setShowButton((e) => !e)}
            style={{ flex: 1, height: "100%" }}
            containerStyle={{ width: "100%", height: "100%" }}
            enableMouseEvents
          >
            {data &&
              data.data.map((item) => (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100%"
                >
                  <img src={item} className={classes.image} />
                </Box>
              ))}
          </SwipeableViews>
          <Grow in={showButton} {...growTimeOut}>
            <IconButton classes={{ root: classes.button }} onClick={_nextImage}>
              <ChevronRightIcon />
            </IconButton>
          </Grow>
          <Grow in={showButton} {...growTimeOut}>
            <IconButton
              classes={{ root: classes.closeButton }}
              onClick={props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Grow>
        </Box>
      </Fade>
    </Modal>
  );
}
