import React, { createElement } from "react";
import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import MapIcon from "@material-ui/icons/Map";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";

export default function FooterArea() {
  const theme = useTheme();
  const footerArray = [
    { content: "桃園市桃園區延壽街50號", icon: MapIcon },
    { content: "0932803300", icon: PhoneIcon },
    { content: "wana.text@gmail.com", icon: MailIcon },
  ];
  return (
    <Box
      display="flex"
      padding={1}
      bgcolor={theme.palette.primary.main}
      minHeight={160}
      alignItems="center"
    >
      <Grid container direction="column" spacing={1}>
        {footerArray.map((item) => (
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              color={theme.palette.primary.dark}
            >
              {createElement(item.icon)}
              <Typography variant="body2">&ensp;{item.content}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
