import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
export default function AspectRatioBox({
  w = 1,
  h = 1,
  ratio,
  width,
  height,
  children,
  style,
}) {
  const box = useRef();
  const [newWidth, setNewWidth] = useState(0);
  const [newHeight, setNewHeight] = useState(0);
  const ratioWidth = w / h;
  const ratioHeight = h / w;
  const reSize = new ResizeObserver(reSizeBox);
  function reSizeBox(entries) {
    for (const entry of entries) {
      let boxInfo = entry.contentRect;
      if (boxInfo) {
        if (width) {
          setNewWidth(boxInfo.width);
        }
        if (height) {
          setNewHeight(boxInfo.height);
        }
      }
    }
  }
  useLayoutEffect(() => {
    const boxCurrent = box.current
    reSize.observe(boxCurrent);
    return () => boxCurrent && reSize.unobserve(boxCurrent);
  }, []);
  useEffect(() => {
    if (width) {
      setNewHeight(newWidth * (ratio ? 1 / ratio : ratioHeight));
    }
  }, [newWidth]);
  useEffect(() => {
    if (height) {
      setNewWidth(newHeight * (ratio || ratioWidth));
    }
  }, [newHeight]);
  return (
    <div
      ref={box}
      style={{
        ...style,
        height: height || newHeight,
        width: width || newWidth,
        display: "flex",
        boxSizing: "border-box",
      }}
    >
      {children}
    </div>
  );
}
